import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

// ---- ---- authentication ---- ---- //
export function useAuth(db) {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, [db]);

  return authUser;
}


// ---- ---- ---- ---- //
const useIsAdmin = (db) => {
  const authUser = useAuth(db);
  const users = useUsers(db);

  // Encuentra al usuario admin
  const admin = users.find((user) => user.email === "hello@danielospid.com");

  // Verifica si el usuario autenticado es el admin
  const isAdmin = authUser && admin && authUser.email === admin.email;

  return isAdmin;
};

export default useIsAdmin;


// ---- ---- banner ---- ---- //
export function useBanner(db) {
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    const bannerRef = collection(db, "banner");

    const unsubscribe = onSnapshot(bannerRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setBanner(data);
    });

    return () => unsubscribe();
  }, [db]);

  return banner;
}

// ---- ---- messages ---- ---- //
export function useMessages(db) {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const ref = collection(db, "messages");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMessages(data);
    });

    return () => unsubscribe();
  }, [db]);

  return messages;
}

// ---- ---- music ---- ---- //
export function useMusic(db) {
  const [music, setMusic] = useState([]);

  useEffect(() => {
    const musicRef = collection(db, "music");

    const unsubscribe = onSnapshot(musicRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMusic(data);
    });

    return () => unsubscribe();
  }, [db]);

  return music;
}

// ---- ---- music ---- ---- //
export function useBlog(db) {
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const ref = collection(db, "blog");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setBlog(data);
    });

    return () => unsubscribe();
  }, [db]);

  return blog;
}

// ---- ---- playlists ---- ---- //
export function usePlaylists(db) {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    const playlistsRef = collection(db, "playlists");

    const unsubscribe = onSnapshot(playlistsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setPlaylists(data);
    });

    return () => unsubscribe();
  }, [db]);

  return playlists;
}

// ---- ---- videos ---- ---- //
export function useVideos(db) {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const VideosRef = collection(db, "videos");

    const unsubscribe = onSnapshot(VideosRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setVideos(data);
    });

    return () => unsubscribe();
  }, [db]);

  return videos;
}

// ---- ---- photos ---- ---- //
export function usePhotos(db) {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const photosRef = collection(db, "photos");

    const unsubscribe = onSnapshot(photosRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setPhotos(data);
    });

    return () => unsubscribe();
  }, [db]);

  return photos;
}

// ---- ---- lyrics ---- ---- //
export function useLyrics(db) {
  const [lyrics, setLyrics] = useState([]);

  useEffect(() => {
    const lyricsRef = collection(db, "lyrics");

    const unsubscribe = onSnapshot(lyricsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setLyrics(data);
    });

    return () => unsubscribe();
  }, [db]);

  return lyrics;
}

// ---- ---- about ---- ---- //
export function useAbout(db) {
  const [about, setAbout] = useState([]);

  useEffect(() => {
    const aboutRef = collection(db, "about");

    const unsubscribe = onSnapshot(aboutRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setAbout(data);
    });

    return () => unsubscribe();
  }, [db]);

  return about;
}

// ---- ---- featured ---- ---- //
export function useFeatured(db) {
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    const ref = collection(db, "featured");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setFeatured(data);
    });

    return () => unsubscribe();
  }, [db]);

  return featured;
}

// ---- ---- products ---- ---- //
export function useProducts(db) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsRef = collection(db, "products");

    const unsubscribe = onSnapshot(productsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setProducts(data);
    });

    return () => unsubscribe();
  }, [db]);

  return products;
}

// ---- ---- links ---- ---- //
export function useLinks(db) {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    const linksRef = collection(db, "links");

    const unsubscribe = onSnapshot(linksRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setLinks(data);
    });

    return () => unsubscribe();
  }, [db]);

  return links;
}

// ---- ---- users ---- ---- //
export function useUsers(db) {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const usersRef = collection(db, "users");

    const unsubscribe = onSnapshot(usersRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setUsers(data);
    });

    return () => unsubscribe();
  }, [db]);

  return users;
}

// ---- ---- fans ---- ---- //
export function useFans(db) {
  const [fans, setFans] = useState([]);
  useEffect(() => {
    const fansRef = collection(db, "fans");

    const unsubscribe = onSnapshot(fansRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setFans(data);
    });
    return () => unsubscribe();
  }, [db]);

  return fans;
}

// ---- ---- settings store ---- ---- //
export function useConfigStore(db) {
  const [configStore, setConfigStore] = useState([]);

  useEffect(() => {
    const configStoreRef = doc(db, "settings", "store");

    const unsubscribe = onSnapshot(configStoreRef, (doc) => {
      if (doc.exists()) {
        setConfigStore({ ...doc.data(), id: doc.id });
      } else {
        console.log("No such document!");
      }
    });
    return () => unsubscribe();
  }, [db]);

  return configStore;
}

// ---- ---- settings website ---- ---- //
export function useConfigWebsite(db) {
  const [configWebsite, setConfigWebsite] = useState([]);

  useEffect(() => {
    const configWebsiteRef = doc(db, "settings", "website");

    const unsubscribe = onSnapshot(configWebsiteRef, (doc) => {
      if (doc.exists()) {
        setConfigWebsite({ ...doc.data(), id: doc.id });
      } else {
        console.log("No such document!");
      }
    });
    return () => unsubscribe();
  }, [db]);

  return configWebsite;
}
