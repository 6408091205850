import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NavMenuIcons(props) {
  return (
    <nav className="nav-menu">
      <ul className="nav-list">
        <li>
          <hr />
        </li>
        <li>
          <button
            type="button"
            className="button icon-button"
            onClick={props.handleMenu}
            title="Menú de navegación"
            aria-label="Abrir menú de navegación"
          >
            <FontAwesomeIcon icon={!props.menu ? faBars : faClose} size="2xl" />
          </button>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenuIcons;
