import {
  faFacebook,
  faInstagram,
  faPaypal,
  faShopify,
  faSpotify,
  faThreads,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Socials() {
  return (
    <div className="footer-socials">
      <ul className="social-icons">
        {/* instagram */}
        <li className="social">
          <a href="https://www.instagram.com/danielospid" target="_blank" rel="noopener noreferrer" title="Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
        {/* thrends */}
        <li className="social">
          <a href="https://www.thrends.net/danielospid" target="_blank" rel="noopener noreferrer" title="Threands">
            <FontAwesomeIcon icon={faThreads} />
          </a>
        </li>
        {/* facebook */}
        <li className="social">
          <a href="https://www.facebook.com/danielospid" target="_blank" rel="noopener noreferrer" title="Facebook">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
        {/* twitter */}
        <li className="social">
          <a href="https://www.twitter.com/danielospid" target="_blank" rel="noopener noreferrer" title="Twitter">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        {/* tiktok */}
        <li className="social">
          <a href="https://www.tiktok.com/@danielospid" target="_blank" rel="noopener noreferrer" title="TikTok">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
        </li>
        {/* youtube */}
        <li className="social">
          <a href="https://www.youtube.com/@danielospid?sub_confirmation=1" target="_blank" rel="noopener noreferrer" title="YouTube">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
        {/* spotify */}
        <li className="social">
          <a href="https://open.spotify.com/artist/3JndD3Uv6uYyupz5W10D9V" target="_blank" rel="noopener noreferrer" title="Spotify">
            <FontAwesomeIcon icon={faSpotify} />
          </a>
        </li>
        {/* shop */}
        <li className="social">
          <a href="https://shop.danielospid.com" target="_blank" rel="noopener noreferrer" title="Shop">
            <FontAwesomeIcon icon={faShopify} />
          </a>
        </li>
        {/* paypal */}
        <li className="social">
          <a href="https://paypal.me/danielospid" target="_blank" rel="noopener noreferrer" title="PayPal">
            <FontAwesomeIcon icon={faPaypal} />
          </a>
        </li>
      </ul>
    </div>
  );
}
export default Socials;
